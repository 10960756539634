<template>
  <vx-card :title="title">
    <vs-prompt
      title="XML"
      color="success"
      :active.sync="activePrompt"
      class="modal-bg"
    >
      <!-- @close="close" -->
      <!-- buttons-hidden="true" -->
      <div
        class="modal-content"
        :style="{ maxHeight: modalHeight + 'px', maxWidth: modalWeight + '%' }"
      >
        <pre>
        <code>{{ selectedRow | formatXml }}</code>
      </pre>
      </div>
    </vs-prompt>

    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="tblServer.data"
          :max-items="tblServer.length"
          :total="tblServer.total"
          @search="handleSearch"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.tblServer.start }} - {{ this.tblServer.end }} of
                  {{ this.tblServer.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in tblServer.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <!-- <vs-th width="5%"
              ><vs-checkbox
                :checked="checkAll"
                @input="onInputCheck(-1)"
              ></vs-checkbox
            ></vs-th> -->
            <vs-th width="5%">Action</vs-th>
            <vs-th>Sales ID</vs-th>
            <vs-th>Canvas Date</vs-th>
            <vs-th>Customer Data</vs-th>
            <vs-th>Canvas SO Data</vs-th>
            <vs-th>SO Value</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  title="Detail"
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="handleDetail(data[indextr])"
                />
                <!-- <div>
                  <vs-checkbox
                    :checked="data[indextr].check"
                    @input="onInputCheck(indextr)"
                  ></vs-checkbox>
                </div> -->
              </vs-td>
              <vs-td>
                Code : {{ data[indextr].personal_code_external }}<br />
                <br />
                Name :{{ data[indextr].personal_name }}
              </vs-td>
              <vs-td>{{ data[indextr].date_time | formatDate }}</vs-td>
              <vs-td v-if="data[indextr].is_onetime">
                Code : <b>{{ data[indextr].customer_code }}</b>
                <br />
                Name : <b>{{ data[indextr].customer_name }}</b>
                <br/>
                Ship to code : {{ data[indextr].ship_to_code }}
                <br />
                Ship to name : {{ data[indextr].ship_to_name }}
                <br />
                Territory : {{ data[indextr].territory_code + " | " + data[indextr].territory_name }}
                <br />
                Dist. Channel : {{ data[indextr].distribution_channel }}
              </vs-td>
              <vs-td v-else>
                Code : {{ data[indextr].customer_code }}
                <br />
                Name : {{ data[indextr].customer_name }}
                <br/>
                Ship to code : {{ data[indextr].ship_to_code }}
                <br />
                Ship to name : {{ data[indextr].ship_to_name }}
                <br />
                Territory : {{ data[indextr].territory_code + " | " + data[indextr].territory_name }}
                <br />
                Dist. Channel : {{ data[indextr].distribution_channel }}
              </vs-td>
              <vs-td>
                Req. Delivery Date : {{ data[indextr].reques_delivery_date | formatDate }}
                <br />
                Co Type : {{ data[indextr].category }}
                <br />
                Notes : {{ data[indextr].note }}
                <br />
              </vs-td>              
              <vs-td>
                total : {{ data[indextr].total | formatCurrency }}
                <br />
                outstanding : {{ data[indextr].outstanding | formatCurrency }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="tblServer.data.length > 0 ? tblServer.totalPage : 0"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>
<script>
import DetailComponent from "./detail";
import moment from "moment";
import xmlFormat from "xml-formatter";

export default {
  components: {
    DetailComponent,
  },
  props: ["tblServer", "checkAll"],
  watch: {},
  data() {
    return {
      title: "Sales Order",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      deleteId: null,
      id: null,
      tableDetails: {},
      selectedRow: null,
      copied: null,
      activePrompt: false,
      selectedXml: null,
      modalHeight: 300,
      modalWeight: 100,
    };
  },
  mounted() {},
  methods: {
    handleSearch(searching) {
      this.$emit("tblSearch", searching);
    },
    handleChangePage(page) {
      this.$emit("tblChangePage", page);
    },
    handleSort(key, active) {
      this.$emit("tblSort", key, active);
    },
    handleChangelength(val) {
      this.$emit("tblChangeLength", val);
    },
    onInputCheck(index) {
      this.$emit("onInputCheck", index);
    },
    handleClose() {
      this.detail = false;
    },
    handleEdit(id) {
      this.$router.push({
        name: "customer-order-edit",
        params: { id: id },
      });
    },
    btnSyncrn(uuid) {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/ocr/sap/send", {
          params: {
            uuid: uuid,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data on processing",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleDetail(co) {
      console.log(co)
      this.$router.push({
        name: "mgcanvas-sales-view",
        params: { id: co.id },
      });
    },
    copyData(data) {
      const el = document.createElement("textarea");
      el.value = data;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.copied = data;
    },
    openConfirm(data) {
      this.activePrompt = true;
      this.selectedRow = data;
    },
  },

  filters: {
    formatDate(value) {
      const sortMonth = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let date = "",
        y = "",
        M = "",
        d = "",
        h = "",
        m = "",
        dateFormat = "";
      if (value) {
        if (value.length == 20) {
          var temp = "";
          for (let i = 0; i < value.length - 1; i++) {
            temp += value[i];
          }
          temp += "+0700";
          console.log(temp);
          date = new Date(temp);
          y = date.getFullYear();
          M = sortMonth[date.getMonth()];
          d = date.getDate();
          h = date.getHours();
          m = date.getMinutes();
          let timeFormat = "";
          if (h != "0" || m != "0") {
            timeFormat = `${h}:${m}`;
          }
          dateFormat = `${d} ${M} ${y} ${timeFormat}`;
        }
        if (dateFormat == "") {
          dateFormat = "invalid date";
        }
        return dateFormat;
      }
    },
    formatStatus(value) {
      if (value == 0) {
        return "Open";
      } else {
        return "Close";
      }
    },
    formatCurrency(value) {
      if (value) {
        return value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    dataToShow(value) {
      if (value.length < 10) {
        return `${value.slice(0, 9)}`;
      } else {
        return `${value.slice(0, 5)}...${value.slice(-5)}`;
      }
    },
    formatXml(xmlString) {
      console.log(xmlString);
      if (xmlString) {
        var xmlFormat1 = xmlFormat(xmlString, {
          indentation: "\t",
          filter: (node) => node.type !== "Comment",
          collapseContent: true,
          lineSeparator: "\n",
        });
        return xmlFormat1;
      } else {
        return "";
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.modal-content {
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: rgb(243, 237, 237);
  overflow-y: auto;
  border-radius: 5px;
}
.modal-bg {
  position: fixed;
  max-width: 100%;
  size: 100%;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 0;
  /* backdrop-filter: blur(5px); efek blur */
  z-index: 999;
}
.con-vs-dialog .vs-dialog {
  max-width: 500px !important;
}
</style>
